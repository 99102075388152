


const TeamData = [
    {
        id: '01',
        title: 'Alextina Ditarson',
        subtitle: 'Transportation Planner',
        // Imga: img1,
    },
    {
        id: '02',
        title: 'Leslie Alexander',
        subtitle: 'Supply Chain Manager',
        // Imga: img2,
    },
    {
        id: '03',
        title: 'Brooklyn Simmons',
        subtitle: 'Logistics Coordinator',
        // Imga: img3,
    },
    {
        id: '04',
        title: 'Marvin McKinney',
        subtitle: 'Warehouse Manager',
        // Imga: img4,
    },
    {
        id: '05',
        title: 'Warehouse Manager',
        subtitle: 'Fleet Supervisor',
        // Imga: img5,
    },
    {
        id: '06',
        title: 'Warehouse Manager',
        subtitle: 'Fleet Supervisor',
        // Imga: img6,
    }
]

export default TeamData;