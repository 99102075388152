import React, { Fragment } from 'react';
import HeaderTop from '../../components/HeaderTop/HeaderTop';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import BlogList from '../../components/BlogList/BlogList'
import CtaSection from '../../components/CtaSection/CtaSection';
import MapSection from '../../components/MapSection/MapSection';
import FooterS2 from '../../components/FooterS2/FooterS2';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Logo from '../../images/logo.svg'
import Footer from '../../components/footer/Footer';

const BlogPage = () => {
    return (
        <Fragment>
            <HeaderTop />
            <Navbar hclass={'wpo-site-header'} Logo={Logo} />
            <PageTitle pageTitle={'Internation Logistics'} pagesub={'Blog'} />
            <BlogList />
            <CtaSection hclass={'wpo-cta-section'}  />
            <div>
                <div style={{marginBottom:'20px'}}>

                </div>
            </div>
            <MapSection/>
            <Footer hclass={'wpo-site-footer'}  />
            {/* <MapSection />
            <CtaSection hclass={'wpo-cta-section-s2'} />
            <FooterS2 /> */}
            <Scrollbar />
        </Fragment>
    )
};
export default BlogPage;

