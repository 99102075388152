// import pimg1 from '../images/project/1.jpg';

import hum1 from '../images/home/T&L1.png'
import hum2 from '../images/home/T &L3.png'
import hum3 from '../images/home/T&L2.png'
import hum4 from '../images/home/T&L4.png'

import tempo from '../images/home/T&L4.png'
import ev from '../images/home/T&L4.png'
import drone from '../images/home/T&L4.png'
import packmove from '../images/home/T&L4.png'




const Projects = [
    {
        id: '01',
        subtitle: 'Tempo delivery',
        title: 'Rail Transportation',
        docomunt: 'Quis id at rhoncus consequat. Aliquam in. Velit phasellus augue tristique integer arcu. Elit sed vestibulum tristique suspendisse ut sit',
        slug: 'Special-Transport-Solutions',
        pimg: hum1,
        pSimg: hum1,
        pSimg2: hum1,
        location: '7 Lake Street,London',
        date: '15 Dec 2024',
    },
    {
        id: '02',
        subtitle: 'EV bike delivery',
        title: 'Air Transportation',
        docomunt: 'Quis id at rhoncus consequat. Aliquam in. Velit phasellus augue tristique integer arcu. Elit sed vestibulum tristique suspendisse ut sit',
        slug: 'Special-Transport-Solutions2',
        pimg: hum2,
        pSimg: hum2,
        pSimg2: hum2,
        location: '7 Lake Street,London',
        date: '15 Dec 2024',
    },
  
    {
        id: '03',
        subtitle: 'Drone delivery',
        title: 'Road Transportation',
        docomunt: 'Quis id at rhoncus consequat. Aliquam in. Velit phasellus augue tristique integer arcu. Elit sed vestibulum tristique suspendisse ut sit',
        slug: 'Special-Transport-Solutions3',
        pimg: hum3,
        pSimg: hum3,
        pSimg2: hum3,
        location: '7 Lake Street,London',
        date: '15 Dec 2024',
    },
  
    {
        id: '04',
        subtitle: 'Packers and Mover',
        title: 'Intermodal Shipping',
        docomunt: 'Quis id at rhoncus consequat. Aliquam in. Velit phasellus augue tristique integer arcu. Elit sed vestibulum tristique suspendisse ut sit',
        slug: 'Special-Transport-Solutions4',
        pimg: hum4,
        pSimg: hum4,
        pSimg2: hum4,
        location: '7 Lake Street,London',
        date: '15 Dec 2024',
    },
  
   

]
export default Projects;






