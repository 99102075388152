
const pricingPlans = [
    {
        title: "Regional Transport",
        Rtitle: "",
        price: 199,
        plan: "Standard pack",
        features: [
            "Container shipping",
            "Storage for goods",
            "Coverage for goods",
            "Hazardous materials handling",
            "Expedited delivery"
        ]
    },
    {
        title: "National Transport",
        Rtitle: "recommend",
        price: 299,
        plan: "Standard pack",
        features: [
            "Container shipping",
            "Storage for goods",
            "Coverage for goods",
            "Hazardous materials handling",
            "Expedited delivery"
        ]
    },
    {
        title: "Global Transport",
        Rtitle: "",
        price: 399,
        plan: "Standard pack",
        features: [
            "Container shipping",
            "Storage for goods",
            "Coverage for goods",
            "Hazardous materials handling",
            "Expedited delivery"
        ]
    },
    {
        title: "Normal Plan",
        parsent:20,
        Rtitle: "",
        Mbps: 30,
        price: "1,599",
        plan: "Standard pack",
        features: [
            "Container shipping",
            "Storage for goods",
            "Coverage for goods",
            "Hazardous materials handling",
            "Expedited delivery"
        ]
    },
    {
        title: "Medium Plan",
        parsent:30,
        Rtitle: "",
        Mbps: 40,
        price: "2,599",
        plan: "Standard pack",
        features: [
            "Container shipping",
            "Storage for goods",
            "Coverage for goods",
            "Hazardous materials handling",
            "Expedited delivery"
        ]
    },
    {
        title: "Premium Plan",
        parsent:15,
        Rtitle: "",
        Mbps: 50,
        price: "3,599",
        plan: "Standard pack",
        features: [
            "Container shipping",
            "Storage for goods",
            "Coverage for goods",
            "Hazardous materials handling",
            "Expedited delivery"
        ]
    }
];

export default pricingPlans;