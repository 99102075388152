import sImg1 from '../images/About_us.png'
import sImg2 from '../images/About_us.png'
import sImg4 from '../images/About_us.png'
import sImg5 from '../images/About_us.png'

import tone from '../images/testiminoialnews/Rectangle 639.png'
import ttwo from '../images/testiminoialnews/Rectangle 640.png'
import tthree from '../images/testiminoialnews/Rectangle 641.png'
import tfour from '../images/testiminoialnews/Rectangle 642.png'


const testimonials = [
    {
        id: '01',
        img: tone,
        Des: "ByHorse has become my go-to transport service for domestic deliveries. Every parcel is handled with care, and deliveries are always on time. Truly dependable!",
        title: 'Priyank S.',
        sub: "Retailer",
    },
    {
        id: '02',
        img: ttwo,
        Des: "I've used ByHorse for numerous shipments across India, and they always exceed expectations. The team is professional, and my goods arrive safely every time.",
        title: 'Ravi K.',
        sub: "Wholesaler",
    },
    {
        id: '03',
        img: tthree,
        Des: "ByHorse combines affordability with excellent service. My shipments reach their destination quickly and securely. Highly recommended for anyone needing reliable logistics in India!",
        title: 'Anjali M.',
        sub: "Business Owner",
    },
    {
        id: '04',
        img: tfour,
        Des: "With ByHorse, I have peace of mind knowing my products are in good hands. Their support team is exceptional, making the whole process smooth and efficient!",
        title: 'Rohit T.',
        sub: "Manufacturer",
    },


]


export default testimonials;