// images


import sam1 from "../images/acam/Rectangle.png";  
import sam2 from "../images/acam/Rectangle1.png";  
import sam3 from "../images/acam/Rectangle2.png";  
import sam4 from "../images/acam/Rectangle3.png";  





const blogs = [
    {
        id: '1',
        title: 'Streamlining Global Supply Chains Our Logistics Solutions',
        title2: 'Express delivery is going to slow down in 2024.',
        tag: 'Transport',
        slug: 'Why-Industry-Are-A-Juicy-Target-For-Cyberattack',
        screens: sam1,
        description: 'It was with the release sheets containing passages more software.',
        author: 'Roger J. Spaulding',
        create_at: 'Sep 03, 2024',
        blogSingleImg: sam1,
        SingleImg: sam1,
        comment: '35',
        day: '28',
        month: 'MAR',
        blClass: 'format-standard-image',
        animation: '1200',
        catagori:'Food and Beverages',
    },
    {
        id: '2',
        title: 'Lead Analyst for Transportation Network and Logistics',
        title2: 'Will supply chains become supply circles?',
        tag: 'Transport',
        slug: 'Why-Industry-Are-A-Juicy-Target-For',
        screens: sam2,
        description: 'It was with the release sheets containing passages more software.',
        author: 'Roger J. Spaulding',
        create_at: 'Sep 03, 2024',
        blogSingleImg: sam2,
        SingleImg: sam2,
        comment: '35',
        day: '28',
        month: 'MAR',
        blClass: 'format-standard-image',
        animation: '1200',
        catagori: 'Fresh Fish & Meat',
    },
    {
        id: '3',
        title: 'Efficient Transport Management Strategies for Businesses',
        title2: 'We ensures you the best quality services',
        tag: 'Transport',
        slug: 'Why-Industry-Are-A-Juicy',
        screens: sam3,
        description: 'It was with the release sheets containing passages more software.',
        author: 'Roger J. Spaulding',
        create_at: 'Sep 03, 2024',
        blogSingleImg: sam3,
        SingleImg: sam3,
        comment: '35',
        day: '28',
        month: 'MAR',
        blClass: 'format-standard-image',
        animation: '1200',
        catagori: 'Culture and Traditions',
    },
    
    {
        id: '4',
        title: 'Principal Transportation and Logistics Network',
        title2: '25 Rules and regulation to be successful in your business.',
        tag: 'Surgery',
        slug: 'Why-Industry-Are-A-Juicy4',
        screens: sam4,
        description: 'It was with the release sheets containing passages more software.',
        author: 'Roger J. Spaulding',
        create_at: 'Sep 03, 2024',
        blogSingleImg: sam4,
        SingleImg: sam4,
        comment: '35',
        day: '28',
        month: 'MAR',
        blClass: 'format-standard-image',
        animation: '1200',
        catagori: 'Culture and Traditions',
    },

];
export default blogs;



