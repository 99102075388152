import React from 'react';
import ProgressWrapper from './ProgressWrapper';

const GetQuateSectionS3 = () => {
    return (
        <section className="wpo-get-quate-section-s3 section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 col-12">
                        <div className="content">
                            <div className="wpo-section-title">
                                <h2>Trusted transport service</h2>
                                <h3>Transportation service
                                    area often considerd</h3>
                            </div>
                            <div className="text">
                                <p>Business is the activity of making one's living or making money by
                                    producing or buying and selling products</p>
                            </div>
                            <ul>
                                <li>
                                    <div className="icon">
                                        <i className="flaticon-delivery"></i>
                                    </div>
                                    <div className="icon-text">
                                        <h2>Global Logistics Operation</h2>
                                        <span>Never at water me might.
                                            hunted unable merely by</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon">
                                        <i className="flaticon-tracking"></i>
                                    </div>
                                    <div className="icon-text">
                                        <h2>Online Tracking System</h2>
                                        <span>Never at water me might.
                                            hunted unable merely by</span>
                                    </div>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <div className="icon">
                                        <i className="flaticon-warehouse-1"></i>
                                    </div>
                                    <div className="icon-text">
                                        <h2>Modern Warehousing Techniq</h2>
                                        <span>Never at water me might.
                                            hunted unable merely by</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon">
                                        <i className="flaticon-customer-support"></i>
                                    </div>
                                    <div className="icon-text">
                                        <h2>Great logistical support.</h2>
                                        <span>Never at water me might.
                                            hunted unable merely by</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-5 col-12">
                        <div className="left-content">
                            <ProgressWrapper />
                        </div>
                    </div>
                </div>
            </div>

            <div className="shape">
                <svg width="191" height="210" viewBox="0 0 191 210" fill="none">
                    <path d="M125.53 208.291H118.13L0 12.271V0.00102234L125.53 208.291Z" fill="white" />
                    <path d="M0 43.1711L99.93 209.121H0V43.1711Z" fill="#FF7236" />
                    <path d="M190.33 209.121H138.45L68.9102 93.7311H120.79L190.33 209.121Z" fill="#0B3742" />
                    <path d="M98.0897 69.1611H72.8097L38.9297 12.9411H64.2097L98.0897 69.1611Z" fill="#FF7236" />
                </svg>
            </div>
        </section>
    );
};

export default GetQuateSectionS3;